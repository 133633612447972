
// Vue reactivity
import { computed, ref, watch } from 'vue';

// icons
import { notifications, notificationsOffOutline, notificationsOutline, chatbubbleEllipsesOutline, createOutline,
        time, location, call, mail, heart, heartOutline,
        statsChart, logoFacebook, logoInstagram, logoGoogle,
        thumbsUpOutline, people, calendarOutline, star, chatbubblesOutline, imagesOutline, compassOutline,
        shareSocialOutline, } from 'ionicons/icons';

// components
import { IonPage, IonContent, IonThumbnail, IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonChip, IonIcon, IonBackButton,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonList,
        IonBadge, IonTextarea, IonButton, IonButtons, IonAvatar, IonProgressBar,
        IonRefresher, IonRefresherContent, IonNote, IonItemDivider, IonSegment, IonSegmentButton,
        loadingController, } from '@ionic/vue';
import ImageSlides from "@/components/slides/ImageSlides.vue";
import SectionHeader from "@/components/SectionHeader.vue";
import StarRating from "@/components/StarRating.vue";

// API services
import { utils } from '@/composables/utils';
import { useRoute, } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';
import PlaceService from '@/services/PlaceService';

export default {
  name: 'PlaceDetailPage',
  components: { IonPage, IonContent, IonThumbnail, IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonChip, IonIcon, IonBackButton,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonList,
                IonBadge, IonTextarea, IonButton, IonButtons, IonAvatar, IonProgressBar,
                IonRefresher, IonRefresherContent, IonNote, IonItemDivider, IonSegment, IonSegmentButton,
                ImageSlides, SectionHeader, StarRating, },
  setup() {
    const store = useStore();
    const { t, locale } = useI18n();
    const route = useRoute();
    const { id, parentPath } = route.params;

    // 1. declare state variables (ref to make them reactive)
    const currUser = computed(() => store.state.user);
    const userLoggedIn = computed(() => store.state.loggedIn);
    const loading = computed(() => store.state.loadingAppPublicData);
    const place = computed(() => store.getters.getPlaceById(id));
    const settings = computed(() => store.state.settings);
    
    const showingStatSection = ref("");
    
    // methods or filters
    const { sleep, formatDateString, numberWithCommas, getRelativeDate, getTypeColor, openBrowser, openSocialShare, findReplaceVariables, } = utils();
    
    const updateUserLikedPlace = async (place: any, action = 'add') => {
      const loading = await loadingController.create({});
      await loading.present();
      store.dispatch('updateUserLikedPlace', { place, id: place.id, action })
      await sleep(1); // 1 seconds
      loading.dismiss();
    }

    const checkUpdateShowingStatSection = () => {
      const { fbLikes, igFollowers, gmRating } = place.value;
      showingStatSection.value = gmRating ? "google" : (fbLikes ? "facebook" : (igFollowers ? "instagram" : ""));
    }

    const sharePlace = async (place: any) => {
      console.log(settings.value.placeSharingMsgTemplate);
      place.sharingUrl = `https://app.mlol.pet/places/${place.id}`;
      place.placeMapLink = place['latLong'] ? encodeURI(`http://maps.apple.com/maps?q=${place['latLong']}`) : "";
      const sharingMsg = findReplaceVariables(settings.value.placeSharingMsgTemplate, place);
      openSocialShare(sharingMsg, place.sharingUrl);
    }

    if (id) {
      PlaceService.addUserBrowsedPlace(id);
      store.commit('addUserBrowsedPlace', id);
    }

    if (place.value.id) {
      if (!place.value.fetchedDetails) {
        store.dispatch('fetchPlaceDetails', { id: place.value.id });
      }
      checkUpdateShowingStatSection();
    } else {
      watch(place, (currPlace) => { // triggered only when direct access to product detail page
        if (!currPlace.fetchedDetails) {
          store.dispatch('fetchPlaceDetails', { id: currPlace.id });
          checkUpdateShowingStatSection();
        }
      });
    }


    // 3. return variables & methods to be used in template HTML
    return {
      // icons
      notifications, notificationsOffOutline, notificationsOutline, chatbubbleEllipsesOutline, createOutline,
      time, location, call, mail, heart, heartOutline,
      statsChart, logoFacebook, logoInstagram, logoGoogle,
      thumbsUpOutline, people, calendarOutline, star, chatbubblesOutline, imagesOutline, compassOutline,
      shareSocialOutline,
      
      // variables
      locale,
      currUser, userLoggedIn, parentPath, loading, place,
      showingStatSection,
      
      // methods
      t, getTypeColor, openBrowser,
      numberWithCommas, formatDateString, getRelativeDate,
      updateUserLikedPlace,
      sharePlace,
    }
  },
}
